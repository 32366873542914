import React, { lazy, Suspense } from "react";
import { render } from "react-dom";
import Loader from "./Loader";

const Main = lazy(() => import("./Main"));

render(
    <Suspense fallback={<Loader />}>
        <Main />
    </Suspense>,
    document.querySelector("#app")
);
