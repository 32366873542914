import { Spinner } from "@vkontakte/vkui";
import React, { FC } from "react";
import "./Loader.less";

export default (() => (

    <div className="Loader">
        <Spinner 
            className="circle" 
            size="large"
        />
    </div>

)) as FC;